import { buildImageUrl } from '@lib/image';
import { SeoData } from '@lib/types';
import Head from 'next/head';

type Props = {
  seo: SeoData | null;
  title: string;
  url: string;
};

export function Seo({ title, seo, url }: Props) {
  const {
    metaDescription,
    metaTitle,
    metaImageUrl,
    metaRobots,
    structuredData,
    canonicalURL,
    keywords,
    metaSocial,
  } = seo ?? {
    metaTitle: title,
    metaDescription: title,
    metaImageUrl: buildImageUrl('/logo.svg'),
    metaSocial: [],
  };

  const facebookData = metaSocial.find(
    (meta) => meta.socialNetwork == 'Facebook',
  );
  const twitterData = metaSocial.find(
    (meta) => meta.socialNetwork == 'Twitter',
  );

  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={facebookData?.title ?? metaTitle} />
      <meta
        property="og:description"
        content={facebookData?.description ?? metaDescription}
      />
      <meta
        property="og:image"
        content={buildImageUrl(facebookData?.image?.url ?? metaImageUrl)}
      />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta
        property="twitter:title"
        content={twitterData?.title ?? metaTitle}
      />
      <meta
        property="twitter:description"
        content={twitterData?.description ?? metaDescription}
      />
      <meta
        property="twitter:image"
        content={buildImageUrl(twitterData?.image?.url ?? metaImageUrl)}
      />

      {metaRobots && (
        <>
          <meta name="robots" content={metaRobots} />
          <meta name="googlebot" content={metaRobots} />
        </>
      )}

      {structuredData && (
        <>
          <script
            type="application/ld+json"
            key="structured-data"
            dangerouslySetInnerHTML={{ __html: structuredData }}
          />
        </>
      )}

      {keywords && <meta name="keywords" content={keywords} />}

      {canonicalURL && <link rel="canonical" href={canonicalURL} />}
    </Head>
  );
}
