import dynamic from 'next/dynamic';
import { Section, Settings } from '@lib/types';

const DynamicImage = dynamic(() => import('./image'), {
  suspense: false,
});

const DynamicTeaserText = dynamic(() => import('./teaser-text'), {
  suspense: false,
});

const DynamicRichText = dynamic(() => import('./rich-text'), {
  suspense: false,
});

const DynamicVideo = dynamic(() => import('./video'), {
  suspense: false,
});

const DynamicCards = dynamic(() => import('./cards'), {
  suspense: false,
});

const DynamicHero = dynamic(() => import('./hero'), {
  suspense: false,
});

const DynamicQuote = dynamic(() => import('./quote'), {
  suspense: false,
});

const DynamicContact = dynamic(() => import('./contact'), {
  suspense: false,
});

const DynamicTitleParagraphLinks = dynamic(
  () => import('./title-paragraph-links'),
  {
    suspense: false,
  },
);

const DynamicTeam = dynamic(() => import('./team'), {
  suspense: false,
});

const DynamicPageTitle = dynamic(() => import('./page-title'), {
  suspense: false,
});

const DynamicEvents = dynamic(() => import('./events'), {
  suspense: false,
});

const DynamicButton = dynamic(() => import('./button'), {
  suspense: false,
});

const DynamicVimeo = dynamic(() => import('./vimeo'), {
  suspense: false,
});

const DynamicHighlight = dynamic(() => import('./highlight'), {
  suspense: false,
});

const DynamicHTML = dynamic(() => import('./html'), {
  suspense: false,
});

const sectionComponents: Record<string, any> = {
  ComponentSectionsImage: DynamicImage,
  ComponentSectionsImageText: DynamicTeaserText,
  ComponentSectionsRichText: DynamicRichText,
  ComponentSectionsVideo: DynamicVideo,
  ComponentSectionsCards: DynamicCards,
  ComponentSectionsHero: DynamicHero,
  ComponentSectionsQuote: DynamicQuote,
  ComponentSectionsTitleParagraphLinks: DynamicTitleParagraphLinks,
  ComponentSectionsTeam: DynamicTeam,
  ComponentSectionsPageTitle: DynamicPageTitle,
  ComponentSectionsEvents: DynamicEvents,
  ComponentSectionsContact: DynamicContact,
  ComponentSectionsButton: DynamicButton,
  ComponentSectionsVideoText: DynamicTeaserText,
  ComponentSectionsHeroVideo: DynamicHero,
  ComponentSectionsVimeo: DynamicVimeo,
  ComponentSectionsHighlight: DynamicHighlight,
  ComponentSectionsHtml: DynamicHTML,
};

const Section = ({
  section,
  settings,
}: {
  section: Section;
  settings: Settings;
}) => {
  const SectionComponent = sectionComponents[section.sectionName];

  if (!SectionComponent) {
    return null;
  }

  return <SectionComponent data={section as any} settings={settings} />;
};

export default function Sections({
  sections,
  settings,
}: {
  sections: Section[];
  settings: Settings;
}) {
  return (
    <>
      {sections.map((section, index) => {
        return <Section key={index} section={section} settings={settings} />;
      })}
    </>
  );
}
